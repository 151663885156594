import React from "react"

import bobPhoto from "../../images/robert.jpg"
import ajPhoto from "../../images/aj.jpg"
import peterPhoto from "../../images/peter-rbai.jpg"

const CustomerFeedback = ({}) => {
  return (
    <div className="max-w-5xl mx-auto px-6 py-10 my-8 lg:my-12">
      <h2 className="text-2xl font-display text-black text-center font-bold leading-8 md:text-4xl sm:leading-tight">
        See what our customers have to say&hellip;
      </h2>
      {/* <p className="mt-3 md:mt-5 md:text-lg text-center">
        We support a wide variety of payment types to suit your needs.
      </p> */}

      <div className="mt-10 lg:mt-16 grid sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-16">
        <div className="flex flex-col items-center">
          <img
            src={bobPhoto}
            alt="Robert – Glide certified expert"
            className="rounded-full w-16 h-16 mr-5"
          />
          <blockquote className="mt-5 leading-snug text-center">
            <span className="block md:text-lg">
              “The Payhere team are super responsive to feedback, I love the
              product!”
            </span>
            <span className="block mt-2 text-xs md:text-base text-gray-700 font-semibold">
              Robert &ndash; Glide certified expert
            </span>
          </blockquote>
        </div>

        <div className="flex flex-col items-center">
          <img
            src={peterPhoto}
            alt="Peter Anderson – Foundation director, RBAI"
            className="rounded-full w-16 h-16 mr-5"
          />
          <blockquote className="mt-5 leading-snug text-center">
            <span className="block md:text-lg">
              “Payhere was cost effective, quick to set up, easy to manage and
              seamless for our donors&hellip;absolutely vital in our sector.”
            </span>
            <span className="block mt-2 text-xs md:text-base text-gray-700 font-semibold">
              Peter &ndash; Foundation director, RBAI
            </span>
          </blockquote>
        </div>

        <div className="flex flex-col items-center">
          <img
            src={ajPhoto}
            alt="AJ – Road Warrior Hub"
            className="rounded-full w-16 h-16 mr-5"
          />
          <blockquote className="mt-5 leading-snug text-center">
            <span className="block md:text-lg">
              “Another Superb Service Experience&hellip; Tech issues and was
              back up and running less than hour! 🤔🧐😎”
            </span>
            <span className="block mt-2 text-xs md:text-base text-gray-700 font-semibold">
              AJ &ndash; Road Warrior Hub
            </span>
          </blockquote>
        </div>
      </div>
    </div>
  )
}

export default CustomerFeedback
