import { times } from "lodash"
import React, { useState } from "react"
import storefrontImg from "../../images/features/storefront.jpg"
import customerPortalImg from "../../images/features/customer-portal.png"
import couponsImg from "../../images/features/coupons.png"
import emailReceiptsImg from "../../images/features/receipts.png"
import embedImg from "../../images/features/embed.png"

const NUMBER_SLIDES = 5

const CarouselNav = ({ slide, count, onChange }) => {
  return (
    <div className="hidden flex-shrink-0 mt-6 mr-6 sm:flex flex-col items-start justify-start">
      {times(count, index => {
        const active = slide === index

        return (
          <button
            className={`w-3 h-3 rounded-full mb-4 ${
              active ? `bg-payhere` : `bg-gray-200`
            }`}
            onClick={() => onChange(index)}
          ></button>
        )
      })}
    </div>
  )
}

const Carousel = ({}) => {
  const [slide, setSlide] = useState(0)

  return (
    <div className="flex flex-col">
      <div className="sm:order-2 max-w-5xl mx-auto px-6 flex items-center justify-center flex-wrap">
        <button
          onClick={() => setSlide(0)}
          className={`px-3 py-2 ${
            slide === 0 ? `bg-payhere text-white` : `bg-gray-100 text-gray-500`
          } md:text-lg rounded-md m-2 md:m-3 font-medium`}
        >
          Storefront
        </button>
        <button
          onClick={() => setSlide(1)}
          className={`px-3 py-2 ${
            slide === 1 ? `bg-payhere text-white` : `bg-gray-100 text-gray-500`
          } md:text-lg rounded-md m-2 md:m-3 font-medium`}
        >
          Customer portal
        </button>
        <button
          onClick={() => setSlide(2)}
          className={`px-3 py-2 ${
            slide === 2 ? `bg-payhere text-white` : `bg-gray-100 text-gray-500`
          } md:text-lg rounded-md m-2 md:m-3 font-medium`}
        >
          Coupon codes
        </button>
        <button
          onClick={() => setSlide(3)}
          className={`px-3 py-2 ${
            slide === 3 ? `bg-payhere text-white` : `bg-gray-100 text-gray-500`
          } md:text-lg rounded-md m-2 md:m-3 font-medium`}
        >
          Email receipts
        </button>
        <button
          onClick={() => setSlide(4)}
          className={`px-3 py-2 ${
            slide === 4 ? `bg-payhere text-white` : `bg-gray-100 text-gray-500`
          } md:text-lg rounded-md m-2 md:m-3 font-medium`}
        >
          Embed
        </button>
      </div>
      <div className="sm:order-1 max-w-5xl mx-auto p-6 flex flex-col md:flex-row md:items-start md:justify-between">
        {slide === 0 && (
          <>
            <div className="md:w-1/2 md:mr-6 flex items-start mb-6 md:mb-0">
              <CarouselNav
                count={NUMBER_SLIDES}
                slide={slide}
                onChange={setSlide}
              />
              <div className="flex-auto">
                <img
                  src={storefrontImg}
                  alt="Storefront"
                  className="max-w-full"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:ml-6 md:mt-6">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
                Storefront
              </h3>
              <p className="md:text-lg mb-3">
                Your free one-page website to promote your product or service
                and embed your payment links.
              </p>

              <p className="md:text-lg mb-3">
                Our simple builder let’s anyone with no technical skills put
                together a storefront in minutes. And with our live preview,
                everytime you make a change your page instantly reloads.
              </p>

              <p className="md:text-lg mb-3">
                Send your customers here to get paid.
              </p>
            </div>
          </>
        )}
        {slide === 1 && (
          <>
            <div className="md:w-1/2 md:mr-6 flex items-start mb-6 md:mb-0">
              <CarouselNav
                count={NUMBER_SLIDES}
                slide={slide}
                onChange={setSlide}
              />
              <div className="flex-auto">
                <img
                  src={customerPortalImg}
                  alt="Customer portal"
                  className="max-w-full"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:ml-6 md:mt-6">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
                Customer portal
              </h3>
              <p className="md:text-lg mb-3">
                Your customers can self-manage their subscriptions, keeping card
                details up to date or cancelling their plan.
              </p>

              <p className="md:text-lg mb-3">
                They can also view a full payment history and download PDF
                receipts which are branded with your organizations logo.
              </p>
            </div>
          </>
        )}
        {slide === 2 && (
          <>
            <div className="md:w-1/2 md:mr-6 flex items-start mb-6 md:mb-0">
              <CarouselNav
                count={NUMBER_SLIDES}
                slide={slide}
                onChange={setSlide}
              />
              <div className="flex-auto">
                <img
                  src={couponsImg}
                  alt="Coupon / discount code support"
                  className="max-w-full"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:ml-6 md:mt-6">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
                Coupon codes
              </h3>
              <p className="md:text-lg mb-3">
                Create coupons to offer your customers discounts on your payment
                links.
              </p>

              <p className="md:text-lg mb-3">
                You can create different durations allowing you to give a
                discount the first time only, for several months or for the
                lifetime of a subscription.
              </p>
            </div>
          </>
        )}
        {slide === 3 && (
          <>
            <div className="md:w-1/2 md:mr-6 flex items-start mb-6 md:mb-0">
              <CarouselNav
                count={NUMBER_SLIDES}
                slide={slide}
                onChange={setSlide}
              />
              <div className="flex-auto">
                <img
                  src={emailReceiptsImg}
                  alt="Slick, branded email receipts"
                  className="max-w-full"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:ml-6 md:mt-6">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
                Email receipts
              </h3>
              <p className="md:text-lg mb-3">
                Slick branded email receipts make your payments look super
                professional! Customize with your logo and brand colors.
              </p>

              <p className="md:text-lg mb-3">
                We stay out of the way, instead keeping the focus on your brand
                and customer experience.
              </p>
            </div>
          </>
        )}
        {slide === 4 && (
          <>
            <div className="md:w-1/2 md:mr-6 flex items-start mb-6 md:mb-0">
              <CarouselNav
                count={NUMBER_SLIDES}
                slide={slide}
                onChange={setSlide}
              />
              <div className="flex-auto">
                <img
                  src={embedImg}
                  alt="No-code payment integration"
                  className="max-w-full"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:ml-6 md:mt-6">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
                Embed in your website
              </h3>
              <p className="md:text-lg mb-3">
                Our embed code allows you to drop your payments into your
                website or no-code platform with just two lines of code.
              </p>

              <p className="md:text-lg mb-3">
                For developers, you can use our embed SDK or drop-in React
                component to unlock even more powerful integrations.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Carousel
