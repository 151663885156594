import React from "react"

import SEO from "../components/seo"
import TrustedBy from "../components/TrustedBy"
import Layout from "../components/Layouts/Base"
import Carousel from "../components/Features/Carousel"
import PaymentTypes from "../components/Features/PaymentTypes"
import Integrations from "../components/Features/Integrations"
import CustomerFeedback from "../components/Features/CustomerFeedback"
import CTA from "../components/Features/CTA"

const FeaturesPage = () => {
  return (
    <Layout>
      <SEO
        title="Features"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <h1 className="text-2xl font-display text-black text-center font-bold leading-8 md:text-6xl sm:leading-tight">
          Everything you need to <br className="hidden lg:block" /> accept
          payments online.
        </h1>
      </div>

      <Carousel />

      <PaymentTypes />

      <Integrations />

      <CustomerFeedback />

      <CTA />

      <TrustedBy />
    </Layout>
  )
}

export default FeaturesPage
