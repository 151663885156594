import React from "react"

const Paymenttypes = ({}) => {
  return (
    <div className="max-w-5xl mx-auto px-6 py-10 my-8 lg:my-12">
      <h2 className="text-2xl font-display text-black text-center font-bold leading-8 md:text-4xl sm:leading-tight">
        Every way you can think to get paid.
      </h2>
      <p className="mt-3 md:mt-5 md:text-lg text-center">
        We support a wide variety of payment types to suit your needs.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1px bg-gray-300 mt-8 lg:mt-12">
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M83.3334 87.5V79.1667C83.3334 74.7464 81.5775 70.5072 78.4518 67.3816C75.3262 64.256 71.087 62.5 66.6667 62.5H33.3334C28.9131 62.5 24.6739 64.256 21.5483 67.3816C18.4227 70.5072 16.6667 74.7464 16.6667 79.1667V87.5"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.9999 45.8333C59.2047 45.8333 66.6666 38.3714 66.6666 29.1667C66.6666 19.9619 59.2047 12.5 49.9999 12.5C40.7952 12.5 33.3333 19.9619 33.3333 29.1667C33.3333 38.3714 40.7952 45.8333 49.9999 45.8333Z"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h4 className="text-lg font-bold text-gray-800 mb-2">
            Individual payments
          </h4>
          <p>
            Create a payment link for an invoice or order and send it to a
            specific customer.
          </p>
        </div>
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M37.4999 91.6667C39.8011 91.6667 41.6666 89.8012 41.6666 87.5C41.6666 85.1988 39.8011 83.3333 37.4999 83.3333C35.1987 83.3333 33.3333 85.1988 33.3333 87.5C33.3333 89.8012 35.1987 91.6667 37.4999 91.6667Z"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M83.3334 91.6667C85.6346 91.6667 87.5001 89.8012 87.5001 87.5C87.5001 85.1988 85.6346 83.3333 83.3334 83.3333C81.0322 83.3333 79.1667 85.1988 79.1667 87.5C79.1667 89.8012 81.0322 91.6667 83.3334 91.6667Z"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.16675 4.16669H20.8334L32.0001 59.9584C32.3811 61.8767 33.4247 63.5999 34.9482 64.8263C36.4717 66.0527 38.378 66.7042 40.3334 66.6667H80.8334C82.7888 66.7042 84.6952 66.0527 86.2186 64.8263C87.7421 63.5999 88.7857 61.8767 89.1667 59.9584L95.8334 25H25.0001"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="text-lg font-bold text-gray-800 mb-2">
            Reusable payments
          </h4>
          <p>
            Make a payment link for your product or service and use it as many
            times as you want.
          </p>
        </div>
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M95.8333 16.6667V41.6667H70.8333"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.16675 83.3333V58.3333H29.1667"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.6251 37.5C16.7383 31.5283 20.3298 26.1892 25.0645 21.9809C29.7992 17.7727 35.5229 14.8324 41.7013 13.4344C47.8797 12.0365 54.3116 12.2265 60.3967 13.9866C66.4819 15.7467 72.022 19.0197 76.5001 23.5L95.8334 41.6667M4.16675 58.3334L23.5001 76.5C27.9782 80.9804 33.5183 84.2533 39.6035 86.0135C45.6886 87.7736 52.1205 87.9636 58.2989 86.5656C64.4773 85.1677 70.2009 82.2274 74.9356 78.0191C79.6704 73.8109 83.2619 68.4718 85.3751 62.5"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="text-lg font-bold text-gray-800 mb-2">
            Subscriptions
          </h4>
          <p>
            Create automatically charging subscriptions, with optional first
            time setup fees.
          </p>
        </div>
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M49.9999 91.6667C73.0118 91.6667 91.6666 73.0118 91.6666 50C91.6666 26.9881 73.0118 8.33331 49.9999 8.33331C26.9881 8.33331 8.33325 26.9881 8.33325 50C8.33325 73.0118 26.9881 91.6667 49.9999 91.6667Z"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50 25V50L66.6667 58.3333"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="text-lg font-bold text-gray-800 mb-2">
            Payment plans
          </h4>
          <p>
            Create automated payment plans to charge customers e.g. $150/month
            for 5 months.
          </p>
        </div>
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M50 4.16669V95.8334"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M70.8333 20.8333H39.5833C35.7156 20.8333 32.0063 22.3698 29.2714 25.1047C26.5365 27.8396 25 31.5489 25 35.4166C25 39.2844 26.5365 42.9937 29.2714 45.7286C32.0063 48.4635 35.7156 50 39.5833 50H60.4167C64.2844 50 67.9937 51.5364 70.7286 54.2713C73.4635 57.0062 75 60.7156 75 64.5833C75 68.4511 73.4635 72.1604 70.7286 74.8953C67.9937 77.6302 64.2844 79.1667 60.4167 79.1667H25"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="text-lg font-bold text-gray-800 mb-2">Donations</h4>
          <p>
            Let your customer/donor enter the amount to pay. Great for NFPs or
            when you trust your customers.
          </p>
        </div>
        <div className="bg-white px-6 py-8 md:p-10 text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto mb-2"
          >
            <path
              d="M95.8334 25L56.2501 64.5833L35.4167 43.75L4.16675 75"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M70.8333 25H95.8333V50"
              stroke="#6C768A"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="text-lg font-bold text-gray-800 mb-2">
            Recurring donations
          </h4>
          <p>
            Receive ongoing donations, the best way to grow your charitable
            giving.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Paymenttypes
