import React from "react"

import zapierLogo from "../../images/features/logos/zapier.png"
import slackLogo from "../../images/features/logos/slack.png"

const Integrations = ({}) => {
  return (
    <div className="bg-black" id="pricing-block">
      <div className="max-w-5xl mx-auto px-6 py-16 md:py-20">
        <h2 className="text-2xl font-display text-white text-center font-bold leading-8 md:text-4xl sm:leading-tight">
          Powerful no-code integrations
        </h2>
        <p className="mt-3 md:mt-5 md:text-lg text-center text-gray-200">
          Connect Payhere to 1,000s of your favourite tools, without touching a
          single line of code.
        </p>

        <div className="max-w-2xl md:mx-auto mt-10 md:mt-12">
          <div className="md:flex md:items-start">
            <div className="md:mr-8 w-full">
              <div className="flex items-start">
                <img
                  src={zapierLogo}
                  alt="Zapier"
                  className="flex-shrink-0 w-10 mr-8"
                />
                <div className="flex-auto">
                  <h4 className="text-white text-lg font-bold mb-1">Zapier</h4>
                  <p className="text-gray-200">
                    Automate actions when you get new payments or subscribers.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 md:mt-0 md:ml-8  w-full">
              <div className="flex items-start">
                <img
                  src={slackLogo}
                  alt="Slack notifications"
                  className="flex-shrink-0 w-10 mr-8"
                />
                <div className="flex-auto">
                  <h4 className="text-white text-lg font-bold mb-1">Slack</h4>
                  <p className="text-gray-200">
                    Get instantly notified when you receive payments.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-white-o10 mt-10 md:mt-12 pt-6">
            <p className="text-gray-300 text-sm">
              And if you’re technical{" "}
              <a
                href="https://developers.payhere.co"
                target="_blank"
                className="underline text-blue-400"
              >
                explore our developer docs
              </a>{" "}
              to find out more about our API, webhooks, React & JavaScript SDKs
              💪
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Integrations
