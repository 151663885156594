import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ContentSection from "./ContentSection"
import { min } from "lodash"

const TrustedBy = () => {
  return (
    <StaticQuery
      query={graphql`
        query TrustedByQuery {
          payhereStats {
            merchants
            payments
            countries
          }
        }
      `}
      render={({ payhereStats }) => (
        <ContentSection title="Powering online payments worldwide.">
          <div className="my-8 md:my-12 flex flex-col gap-8 md:flex-row md:justify-between">
            <div>
              <span className="block text-7xl text-payhere font-display font-semibold">
                {payhereStats.merchants.toLocaleString()}
              </span>
              <span className="block text-lg">Merchants</span>
            </div>
            <div>
              <span className="block text-7xl text-payhere font-display font-semibold">
                $20M+
              </span>
              <span className="block text-lg">Payments processed</span>
            </div>
            <div>
              <span className="block text-7xl text-payhere font-display font-semibold">
                {min([payhereStats.countries, 179])}
              </span>
              <span className="block text-lg">Countries using Payhere</span>
            </div>
          </div>
        </ContentSection>
      )}
    />
  )
}

export default TrustedBy
