import React from "react"

const ContentSection = ({ title, children }) => {
  return (
    <div className="border-t border-gray-200">
      <div className="max-w-5xl mx-auto px-6 py-16 md:py-28">
        {title && (
          <h2 className="font-display text-black text-2xl md:text-4xl font-bold mb-5 leading-tight">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  )
}

export default ContentSection
