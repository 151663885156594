import React from "react"

import AppLink from "../AppLink"
import { supportEmail, signupsDisabled } from "../../utils/config"
import stripeVerifiedLogo from "../../images/stripe-verified-partner.svg"
import { starterPercentage } from "../../utils/constants"

const CTA = ({}) => {
  return (
    <div className="bg-black" id="pricing-block">
      <div className="max-w-5xl mx-auto px-6 py-16 md:py-20">
        <h2 className="text-2xl font-display text-white text-center font-bold leading-8 md:text-4xl sm:leading-tight">
          Ready? It’s risk-free.
        </h2>
        <p className="mt-3 md:mt-5 md:text-lg text-center text-gray-200">
          There are no monthly commitents. Pricing starts at just{" "}
          {starterPercentage} per transaction plus Stripe fees for card
          processing. You are minutes away from accepting payments online.
        </p>

        <div className="mt-10 md:mt-12">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center sm:mr-6">
            <AppLink
              path="/signups/new"
              params={{ plan: "starter" }}
              className="bg-payhere border-2 border-payhere text-center text-white text-lg font-semibold font-display py-3 px-4 rounded-md mb-4 sm:mb-0 sm:mr-4"
            >
              {signupsDisabled ? `Book a demo` : `Get started for free`}
            </AppLink>

            <a
              href={supportEmail}
              target="_blank"
              className="border-2 border-gray-300 text-center text-gray-300 text-lg font-semibold font-display py-3 px-4 rounded-md"
            >
              Have questions?
            </a>
          </div>

          <p className="text-center mt-2 text-gray-400 text-xs md:text-sm">
            No credit card needed.
          </p>

          <img
            src={stripeVerifiedLogo}
            alt="Stripe Verified Partner"
            className="mt-8 md:mt-10 mx-auto"
          />
        </div>
      </div>
    </div>
  )
}

export default CTA
